import { Card, CardBody, CardFooter, CardHeader } from "@nextui-org/card";
import { Button, Input, Divider } from "@nextui-org/react";
import { useState } from "react";
import { useRouter } from "next/router";
import { useMutation } from "@tanstack/react-query";
import Link from "next/link";

import DefaultLayout from "@/layouts/default";
import { apiLogin } from "@/api/api";
import { useUser } from "@/provider/UserProvider";

export default function LoginPage() {
  const { push } = useRouter();
  const { refresh, logIn } = useUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { mutate, isPending } = useMutation({
    mutationKey: ["auth", "login"],
    mutationFn: () => apiLogin({ email, password }),
    onSuccess: async () => {
      logIn();
    },
  });

  const handleLogin = async () => {
    mutate();
  };

  return (
    <DefaultLayout>
      <section className="flex flex-col items-center justify-center gap-4 py-8 md:py-10">
        <Card className="mx-auto max-w-sm">
          <CardHeader className="pb-4 pt-2 px-4 flex-col items-start">
            <p className="text-2xl mb-3 uppercase font-bold">Login</p>
            <small className="text-default-500">
              Enter your email below to login to your account
            </small>
          </CardHeader>
          <Divider />
          <CardBody>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Input
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="grid gap-2">
                <Input
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="grid gap-2">
                <p className="text-default-500 text-sm">
                  By clicking Login, you agree to our{" "}
                  <Link className="text-primary" href="/terms-of-service">
                    Terms of Service.
                  </Link>
                </p>
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <Button
              className="w-full"
              color="primary"
              isLoading={isPending}
              variant="shadow"
              onClick={handleLogin}
            >
              Login
            </Button>
          </CardFooter>
        </Card>
      </section>
    </DefaultLayout>
  );
}
